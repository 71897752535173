// @js-import "@common/common-dependencies.js"
// @js-import "@shared/height.js"

ComponentsManager.register('small_teaser_tiles', function () {
    var ScreenSize = rwd.ScreenSize,
        HeightUtil = rwd.HeightUtil;

    var privateMethod = {
        bemElements: {
            header: '.small_teaser_tiles__header',
            elements: '.small_teaser_tiles__elements',
            swiper: '.small_teaser_tiles__swiper',
            swiperScroller: '.small_teaser_tiles__swiperScroller',
            anchor: '.small_teaser_tiles__anchor'
        },
        classes: {
            halfColumn: 'half_column',
            isActive: 'isActive',
            isModal: 'modal',
            isSynced: 'isSynced',
        },
        componentMode: function(componentType, $componentElement) {
            return $componentElement.find('.' + componentType).length > 0 ? true : false;
        },
        isModal: function(anchor) {
            return anchor.hasClass(this.classes.isModal);
        },
        delay: {
            forAction: 150
        },
        syncHeightForHeader: function($componentElement) {
            return HeightUtil.resetAndSyncHeight($componentElement.find(privateMethod.bemElements.header));
        },
        syncHeight: function($componentElement) {
            return {
                syncHeightForHeader: privateMethod.syncHeightForHeader($componentElement),
                syncHeightItemsForMobile: privateMethod.syncHeightItemsForMobile($componentElement),
            };
        },
        syncHeightItemsForMobile: function($componentElement) {
            var swiper = $componentElement.find(this.bemElements.swiper),
                swiperScroller = $componentElement.find(this.bemElements.swiperScroller),
                isSynced = this.classes.isSynced;
            if ( privateMethod.componentMode('swipeable_list', $componentElement) ) {
                return deviceUtils.isMobile
                    ? HeightUtil.resetAndSyncHeight(swiper,function() {
                        swiperScroller.addClass(isSynced);
                      })
                    : (HeightUtil.resetHeight(swiper) & swiperScroller.removeClass(isSynced));
            }
        },
        layoutDetect: function($componentElement) {
            if($componentElement.closest('.layout_column').is('.col-md-6, .col-lg-6')) {
                $componentElement.addClass(privateMethod.classes.halfColumn);
            }
        },
        tapEffect: function($componentElement) {
            const _this = this;
            $componentElement
                .find(_this.bemElements.anchor)
                .onPage('click', function(evt) {
                    if (deviceUtils.isMobile) {
                        evt.preventDefault();
                        const $anchor = $(this),
                              href = $anchor.attr('href');
                        if (!_this.isModal($anchor)) {
                            $anchor
                                .addClass(_this.classes.isActive)
                                .delay(_this.delay.forAction)
                                .queue(function() {
                                    window.scroll(0, 0); // for scroll without animate
                                    fn.changeLocation(href);
                                    $anchor
                                        .removeClass(_this.classes.isActive)
                                        .dequeue();
                                });
                        }
                    }
                });
        },
        runTiles: function($componentElement) {
            return {
                syncHeight: this.syncHeight($componentElement),
                layoutDetect: this.layoutDetect($componentElement),
                tapEffect: this.tapEffect($componentElement)
            }
        }
    };
    return {
        init: function( componentId, $componentElement ) {

            var _this = privateMethod;
            _this.runTiles($componentElement);

            $(window).onPage( "Tabs:openActiveItem", function( _, itemNode, isActive ) {
                if (itemNode.hasClass(isActive)) {
                    _this.syncHeight($componentElement);
                }
            });
            $(window).onPage(ScreenSize.SIZE_CHANGE_EVENT_NAME, function () {
                _this.syncHeight($componentElement);
            });
        }
    }
});
